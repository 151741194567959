import React, { Component, Fragment, useContext } from "react";
import Layout from "../components/layout";
import { navigate } from '@reach/router';
// import PropTypes from 'prop-types'
import Header from "../components/Header";
import styled from "styled-components"
// import queryString from 'query-string'
import Text, {Section} from "../components/Theme";
import { AnimatePresence, motion } from "framer-motion";
import Close from "../components/Header/close";
import media from "styled-media-query";
import PrintUnavailable from "../components/no-print.js";
// import { motion } from "framer-motion";
// import { graphql, Link } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Footer from "../components/footer.js";

import IntroSectionBackground from "../images/background__daily-2.jpg";
import chevronRight from '../images/icons/icon__chevron--white--right.svg';
import EmailIcon from '../images/icons/icon__mail.svg';
import TwitterIcon from '../images/icons/icon__twitter.svg';
import FacebookIcon from '../images/icons/icon__facebook.svg';
import LinkIcon from '../images/icons/icon__facebook.svg';

const IntroSection = styled.div`
    background-image: url(${IntroSectionBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    color: var(--white);
    margin-bottom: 60px;

    ${media.lessThan("medium")`
        margin-bottom: 10px;
    `}

    Section {
        min-height: calc(60vh - 100px);
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-flow: column-reverse wrap;
        align-items: baseline;
        padding-bottom: 10vh;
    }

    .intro {
        &__category {
            text-transform: uppercase;
            border: 1px solid var(--white);
            padding: 4px 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
`;

const NewsletterContainer = styled.div`
    grid-column: 1/13;
    padding: 0;

    h3 {
        margin-top: 0;
    }
`;

const SubscribeBox = styled.div`
    // grid-column: 2/11;
    // padding: 50px 0 0 0;

    input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0;
        width: 100%;
        border: 0px;
        background-color: #f1f1f1;
        background-repeat: no-repeat;
        background-position: 1.5rem center;
        height: 63px;
        font-size: 20px;
        padding: 1rem 2rem;
        grid-column: 1/13;

        &::placeholder {
            opacity: 0.3;
        }

        &[type="submit"] {
            border: none;
            background-size: 14px;
            background-position: calc(100% - 1rem) center;
            background-repeat: no-repeat;
            background-image: url(${chevronRight});
            background-color: var(--black);
            color: var(--white);
            width: 100%;
            min-width: 100px;
            max-width: 180px;
            text-align: left;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            height: 63px;
            font-size: 20px;
            line-height: 1;
        }
    }
`;

const NewsletterTitle = styled(Text)`
    margin-bottom: 20px;
    color: var(--black);
    font-family: var(--nhp);
    font-weight: 600;
    line-height: 1.2;
    width: 80%;

    a {
        display: block;
        font-size: 11px;
        padding-top: 20px;
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }
`;

const NewsletterParagraph = styled.p`
    color: var(--grey);
    font-family: var(--nhp);
    font-size: 18px;
    line-height: 1.2;
    margin: 20px 0;
`;

const SocialContainer = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CloseBtn = styled.div`
    position: fixed;
    top: 40px;
    right: 40px;

    g {
        fill: #fff !important;
    }

    ${media.greaterThan("medium")`
        top: 140px;
        right: 140px;
    `}
`;

const ArticleLink = styled.span`
    margin-top: 10px;
    display: block;

    a {
        text-decoration: underline;
    }
`;

const PrintModal = styled.div`
    position: fixed;
    background-color: #fff;
    padding: 0;
    z-index: 9997;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    ${media.greaterThan("medium")`
        padding: 100px;
    `}
`;

const DailyFlex = styled.div`
    padding: 0 30px;

    ${media.lessThan("medium")`
        padding: 30px 10px;
    `}

    ${media.lessThan("small")`
        padding: 30px 0;
    `}

    &:nth-of-type(even) {
        margin-top: 60px;

        ${media.lessThan("medium")`
            margin-top: 0;
        `}

        ${media.greaterThan("medium")`
            margin-top: 90px;
        `}
    }
`

const OverviewContent = styled.div`
    width: 100%;
    padding: 25px 0;
    margin-bottom: 60px;

    ${media.lessThan("medium")`
        margin-bottom: 0;
    `}

    ${media.greaterThan("medium")`
        grid-column-start: 8;
    `}

    .overview-geo {
        margin-bottom: 30px;
        opacity: 0.7;

        ${media.lessThan("medium")`
            margin-bottom: 15px;
        `}
    }

    .overview-source {
        opacity: 0.7;
    }

    .overview-description {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 40px;

        ${media.lessThan("medium")`
            margin-bottom: 20px;
        `}
    }

    .meta-btns {
        ${media.lessThan("large")`
            display: block;

            .share-container {
                margin-top: 20px;
            }
        `}
    }

    .daily-headers {
        font-size: 30px;
        margin-bottom: 5px;

        ${media.lessThan("medium")`
            font-size: 28px;
        `}
    }
`;

const OverviewImg = styled.div`
    max-width: 100%;
    display: block;
    width: 100%;

    ${media.greaterThan("medium")`
        align-items: center;
        grid-column: 2/8;
        padding-right: 0;
        padding-left: 0;
    `}
`

const CloseButton = styled.button`
    background: transparent;
    font-size: 36px;
    color: #fff;
    opacity: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999;
`

const LeftButton = styled.button`
    background: transparent;
    font-size: 36px;
    color: #fff;
    opacity: .2;
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 20px;
    &.active {
        opacity: 1;
    }
`

const RightButton = styled.button`
    background: transparent;
    font-size: 36px;
    color: #fff;
    opacity: .2;
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 9999;

    &.active {
        opacity: 1;
    }

    &:hover {
        transform: scale(1.2);
    }
`

const LightboxModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.94);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
`
const LightboxContent = styled.div`
  width: 100%;
  height: 95vh;

  .gatsby-image-wrapper {
      height: 95vh;

      img {
          object-fit: contain !important;
      }
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftRight = styled.div`
    position: absolute;
    top: 50%;
    width: 100%;

    button:first-child {
        margin-right: 10px;
    }
`

class DailyPage extends Component {
    state = {
        unavailable: false,
        hovered: false,
        printName: {},
        showLightbox: false,
        selectedImage: 0,
        imgId: false,
    };

    componentDidMount = () => {
        window.addEventListener('keyup', this.handleKeyUp, false)
    }

    componentWillUnmount = () => {
        window.removeEventListener('keyup', this.handleKeyUp, false)
    }

    handleClick = (e, index) => {
        e.preventDefault()
        this.setState({ showLightbox: !this.state.showLightbox, selectedImage: index })
    }

    closeLightbox = parameter => {
        this.setState({ showLightbox: false })
        navigate('#' + parameter);
    }

    goBack = () => {
        this.setState({ selectedImage: this.state.selectedImage - 1 })
    }

    goForward = () => {
        this.setState({ selectedImage: this.state.selectedImage + 1 })
    }

    handleKeyUp = e => {
        e.preventDefault()
        const { keyCode } = e
        if (this.state.showLightbox) {
            if (keyCode === 37) {
                // Left Arrow Key
                if (this.state.selectedImage > 0) {
                    this.setState({ selectedImage: this.state.selectedImage - 1 })
                }
            }
            if (keyCode === 39) {
                // Right Arrow Key
                if (this.state.selectedImage < 19) {
                    this.setState({ selectedImage: this.state.selectedImage + 1 })
                }
            }
            // if (keyCode === 27) {
            //     // Escape key
            //     this.setState({ showLightbox: false })
            // }
        }
    }

    setHover = () => this.setState({ hovered: true });
    cancelHover = () => this.setState({ hovered: false });

    openModal = parameter => {
        const { unavailable } = this.state;
        if (!unavailable) {
            this.setState({
                unavailable: true,
                printName: parameter
            });
        }
    };

    closeModal = () => {
        const { unavailable } = this.state;
        if (unavailable) {
            this.setState({ unavailable: false });
        }
    };

    render() {
        const { showLightbox, selectedImage } = this.state
        const facebookUrl =
            "https://www.facebook.com/sharer/sharer.php?u=https://www.over-view.com/overviews/";

        const {prevPageSuffix, nextPageSuffix, overviews, firstIndex,
               lastIndex, totalCount} = this.props.pageContext;

        return (
            <Fragment>
                <Layout parent="daily-page">
                    <Header content_color="black" />
                    <IntroSection>
                        {/* TODO: REPLACE WITH CMS FIELDS */}
                        {/* TODO: ADD VIGNETTE LAYER */}
                        <Section>
                            <div className="intro__content">
                                <Text ultra>
                                    Daily
                                </Text>
                                <Text body>
                                    One regular dose of Earth from above
                                    <br />
                                    <br />
                                </Text>
                            </div>
                        </Section>
                    </IntroSection>

                    <div className="grid-container">
                        <div className="grid-x">

                            {overviews.map(({ node: overview }, i) => (
                                <DailyFlex key={overview.id} id={overview.id} className="cell small-12 medium-6">
                                    <OverviewImg
                                        gutter={overview.image.fluid.aspectRatio}
                                    >
                                        <a
                                            href={overview.image.fluid.src}
                                            alt="Car Image"
                                            onClick={e => this.handleClick(e, i)}
                                        >
                                            <Img
                                                fluid={overview.image.fluid}
                                                alt={overview.title}
                                                style={{ backgroundColor: "black" }}
                                                durationFadeIn={500}
                                                placeholderStyle={
                                                    {
                                                        // "filter": "blur(10px) contrast(1.5) saturate(1.5)",
                                                        // "boxShadow": "0 0 100px 200px inset black",
                                                    }
                                                }
                                            />
                                        </a>
                                    </OverviewImg>

                                    {/* TODO: Add Category Here */}
                                    <OverviewContent>
                                        <h3
                                            id="w-node-0e4a4f7ac915-5940d611"
                                            className="subheader daily-headers"
                                        >
                                            {overview.title}
                                        </h3>
                                        <p className="caption overview-geo">
                                            {overview.geo.latitude.toFixed(6)}°,
                                            {overview.geo.longitude.toFixed(6)}° - {overview.source}
                                            <br />
                                        </p>
                                        <p className="overview-description">
                                            {overview.description}
                                            {overview.articleLink && (
                                                <ArticleLink>
                                                    <Link
                                                        to={`/stories/${overview.articleLink.slug}`}
                                                    >
                                                        Read the full story &rarr;
                                                    </Link>
                                                </ArticleLink>
                                            )}
                                        </p>
                                        <div className="meta-btns">
                                            {overview.shopLink && (
                                                <Link to={overview.shopLink}>
                                                  <button
                                                    className="section-button"
                                                  >
                                                      <span>Shop</span>
                                                      <img
                                                          src={require("../images/icons/icon__chevron--white--right.svg")}
                                                          alt="daily overview"
                                                          className="image-icon"
                                                      />
                                                  </button>
                                                </Link>
                                            )}

                                            {!overview.shopLink && (
                                              <button
                                                className="section-button"
                                                onClick={this.openModal.bind(
                                                    this,
                                                    overview.title
                                                )}
                                              >
                                                  <span>Shop</span>
                                                  <img
                                                      src={require("../images/icons/icon__chevron--white--right.svg")}
                                                      alt="daily overview"
                                                      className="image-icon"
                                                  />
                                              </button>
                                            )}

                                            <p className="share-container">
                                                <span
                                                    href="#"
                                                    className="copy__share"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText("https://www.over-view.com/overviews/" + overview.slug);
                                                    }}
                                                >
                                                    <img
                                                        src={require("../images/icons/icon__link--grey.svg")}
                                                        alt="daily overview: email"
                                                        className="image-icon__medium"
                                                    />
                                                </span>
                                                <a
                                                    href={`mailto:?subject=Check out this post on Overview&body=Check out the ${overview.title} post on Overview: https://www.over-view.com/overviews/${overview.slug}`}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={require("../images/icons/icon__mail--grey.svg")}
                                                        alt="daily overview: email"
                                                        className="image-icon__medium"
                                                    />
                                                </a>
                                                <a
                                                    href={
                                                        facebookUrl + overview.slug
                                                    }
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={require("../images/icons/icon__facebook--grey.svg")}
                                                        alt="daily overview: email"
                                                        className="image-icon__medium"
                                                    />
                                                </a>
                                                <a
                                                    href={
                                                        "http://twitter.com/share?text=" + overview.title + "&url=https://www.over-view.com/overviews/" + overview.slug
                                                    }
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={require("../images/icons/icon__twitter--grey.svg")}
                                                        alt="daily overview: email"
                                                        className="image-icon__medium"
                                                    />
                                                </a>
                                            </p>
                                        </div>
                                    </OverviewContent>
                                </DailyFlex>
                            ))}

                        </div>
                    </div>

                    {/* TODO: REPLACE WITH COMPONENT VERSION OF NEWSLETTER SIGNUP */}
                    {/*<NewsletterSignup />*/}
                    <Section small className="subscribe-section subscribe-section__grey">
                        <NewsletterContainer>
                            <div className="grid-container">
                                <div className="grid-x grid-padding-x grid-padding-y">
                                    <div className="cell small-12 medium-6">
                                        {/* TODO: REPLACE WITH CMS FIELDS */}
                                        <SubscribeBox>
                                            <NewsletterTitle sub>
                                                To receive our daily post and the occasional announcement:
                                            </NewsletterTitle>
                                            <form
                                                action="https://www.getrevue.co/profile/dailyoverview/add_subscriber"
                                                method="post"
                                                id="revue-form"
                                                name="revue-form"
                                                target="_blank"
                                                className="subscribe-section__form"
                                            >
                                                <input
                                                    className="revue-form-field"
                                                    placeholder="Enter your email"
                                                    type="email"
                                                    name="member[email]"
                                                    id="member_email"
                                                />
                                                <input
                                                    type="submit"
                                                    value="Submit"
                                                    name="member[subscribe]"
                                                    id="member_submit"
                                                    className="revue-form-submit"
                                                />
                                            </form>
                                            {/*<NewsletterParagraph>
                                                Subscribe to receive our best posts once a month
                                            </NewsletterParagraph>*/}
                                        </SubscribeBox>
                                    </div>
                                    <div className="cell small-12 medium-6">
                                        <h3>Follow Overview on Social</h3>
                                        {/*<NewsletterParagraph>
                                            Join us on the social networks for the latest updates
                                        </NewsletterParagraph>*/}
                                        <SocialContainer>
                                            <a className="newsletter-social newsletter-social__facebook"
                                               href="https://www.facebook.com/doverview"
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               key="SocialContainer1"
                                            >
                                                <img
                                                    src={require("../images/icons/icon__facebook.svg")}
                                                    width="24"
                                                    alt="Daily Overview"
                                                    className="grid-right"
                                                />
                                            </a>
                                            <a className="newsletter-social newsletter-social__twitter"
                                               href="https://twitter.com/DOverview"
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               key="SocialContainer2"
                                            >
                                                <img
                                                    src={require("../images/icons/icon__twitter.svg")}
                                                    width="24"
                                                    alt="Daily Overview"
                                                    className="grid-right"
                                                />
                                            </a>
                                            <a className="newsletter-social newsletter-social__instagram"
                                               href="https://www.instagram.com/dailyoverview/"
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               key="SocialContainer3"
                                            >
                                                <img
                                                    src={require("../images/icons/icon__instagram.svg")}
                                                    width="24"
                                                    alt="Daily Overview"
                                                    className="grid-right"
                                                />
                                            </a>
                                            <a className="newsletter-social newsletter-social__amazon"
                                               href="https://www.amazon.com/Benjamin-Grant/e/B01HMX7AZU/"
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               key="SocialContainer4"
                                            >
                                                <img
                                                    src={require("../images/icons/icon__amazon.svg")}
                                                    width="24"
                                                    alt="Daily Overview"
                                                    className="grid-right"
                                                />
                                            </a>
                                        </SocialContainer>
                                    </div>
                                </div>
                            </div>
                        </NewsletterContainer>
                    </Section>

                    <Footer />
                    {this.state.unavailable ? (
                        <PrintModal>
                            <PrintUnavailable name={this.state.printName} />
                            <CloseBtn onClick={this.closeModal}>
                                <Close />
                            </CloseBtn>
                        </PrintModal>
                    ) : null}
                </Layout>
            </Fragment>
        );
    }
}

export default DailyPage;



// export const query = graphql`
// query {
//     allDatoCmsOverview {
//        edges {
//            node {
//                id
//                title
//                source
//                description
//                shopLink
//                category {
//                    name
//                }
//                slug
//                image {
//                    fluid(
//                        maxWidth: 2000
//                        imgixParams: {
//                            q: 85
//                            dpr: 3
//                            w: "2000"
//                            fit: "clip"
//                            auto: "compress"
//                        }
//                    ) {
//                        ...GatsbyDatoCmsFluid
//                    }
//                }
//                geo {
//                    latitude
//                    longitude
//                }
//            }
//        }
//     }
// }
// `;


